import React from 'react'
import { baseUrl } from '../../helper/helper'

const SalesDetailsSection = () => {
    
  return (
    <div className="col-lg-4">
              <div className="white_card card_height_100 mb_30 overflow_hidden">
                <div className="white_card_header">
                  <div className="box_header m-0">
                    <div className="main-title">
                      <h3 className="m-0">Sales Details</h3>
                    </div>
                    <div className="header_more_tool">
                      <div className="dropdown"> <span className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"> <i className="ti-more-alt"></i> </span>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                             <a className="dropdown-item" href="#"> <i className="ti-printer"></i> Print</a>
                              <a className="dropdown-item" href="#"> <i className="fa fa-download"></i> Download</a> 
                              </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white_card_body pb-0">
                  <div className="Sales_Details_plan">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="single_plan d-flex align-items-center justify-content-between">
                          <div className="plan_left d-flex align-items-center">
                            <div className="thumb"> 
                                <img src={`${baseUrl}front/img/icon2/3.svg`} alt="" /> </div>
                            <div>
                              <h5>2000</h5>
                              <span>Total Collected COD</span> </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single_plan d-flex align-items-center justify-content-between">
                          <div className="plan_left d-flex align-items-center">
                            <div className="thumb"> 
                                <img src={`${baseUrl}front/img/icon2/1.svg`} alt="" /> </div>
                            <div>
                              <h5>1200</h5>
                              <span>Next COD (DATE)</span> </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single_plan d-flex align-items-center justify-content-between">
                          <div className="plan_left d-flex align-items-center">
                            <div className="thumb">
                                 <img src={`${baseUrl}front/img/icon2/4.svg`} alt="" /> </div>
                            <div>
                              <h5>800</h5>
                              <span>Future COD</span> </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default SalesDetailsSection

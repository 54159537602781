import React, { useEffect } from 'react'
import $ from 'jquery';
// import 'datatables.net';
// import 'datatables.net-dt/css/jquery.dataTables.min.css'; // DataTables CSS

const OrderStatusSection = () => {



    useEffect(() => {
        if (window.$ && window.$.fn.DataTable) {
            const table = window.$('#DataTables_Table_1').DataTable({
                bLengthChange: false,
                bDestroy: true,
                language: {
                  search: "<i class='ti-search'></i>",
                  searchPlaceholder: 'Quick Search',
                  paginate: {
                    next: "<i class='ti-arrow-right'></i>",
                    previous: "<i class='ti-arrow-left'></i>",
                  },
                },
                columnDefs: [
                  { visible: false },
                ],
                responsive: true,
                searching: false,
              });

            // Cleanup on component unmount
            //   return () => {
            //     if (window.$.fn.DataTable.isDataTable('#DataTables_Table_1')) {
            //       table.destroy(true);
            //     }
            //   };
        }
    }, []);

    return (
        <>
            
                <div className="col-lg-8">
                    <div className="white_card card_height_100 mb_20 ">
                        <div className="white_card_header">
                            <div className="box_header m-0">
                                <div className="main-title">
                                    <h3 className="m-0">Order Status By Product :: Till Now</h3>
                                </div>
                                <div className="header_more_tool">
                                    <div className="dropdown">
                                        <span className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown">
                                            <i className="ti-more-alt"></i>
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <a className="dropdown-item" href="#a"> <i className="ti-trash"></i> Delete</a>
                                            <a className="dropdown-item" href="#a"> <i className="ti-printer"></i> Print</a>
                                            <a className="dropdown-item" href="#a"> <i className="fa fa-download"></i> Download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="white_card_body QA_section">
                            <div className="QA_table ">
                                <div id="" className="dataTables_wrapper no-footer">
                                    <div id="DataTables_Table_1_wrapper" className="dataTables_wrapper no-footer"><table className="table tableDatatotal lms_table_active2 p-0 dataTable no-footer dtr-inline" id="DataTables_Table_1" role="grid" style={{ width: '1162px' }}>
                                        <thead>
                                            <tr role="row">
                                                <th  aria-sort="ascending">Product Name</th>
                                                <th  >Total</th>
                                                <th  >New Order</th>
                                                <th  >Cancel By DS</th>
                                                <th >Cancel By System</th>
                                                <th  >Delivered</th>
                                                <th  >RTO</th>
                                                {/* <th scope="col" className="sorting" tabIndex="0" aria-controls="DataTables_Table_1" rowSpan="1" aria-label="Margin: activate to sort column ascending" colSpan="1" style={{ width: '59.2px' }}>Margin</th> */}
                                                </tr>
                                        </thead>
                                        <tbody>

                                            <tr role="row" className="odd">
                                                <td tabIndex="0" className="sorting_1">
                                                    3 in 1 BLACK Hair Color Shampoo pack of 2
                                                </td>
                                                <td className="color_text_6">95</td>
                                                <td className="color_text_6">0</td>
                                                <td className="color_text_6">36</td>
                                                <td className="color_text_6">1</td>
                                                <td className="color_text_6">13</td>
                                                <td className="color_text_6">45</td>
                                                {/* <td className="color_text_6">22.41%</td> */}
                                            </tr><tr role="row" className="even">
                                                <td tabIndex="0" className="sorting_1">
                                                    Adhesive tape-Alluminum Foil Tape
                                                </td>
                                                <td className="color_text_6">95</td>
                                                <td className="color_text_6">0</td>
                                                <td className="color_text_6">36</td>
                                                <td className="color_text_6">1</td>
                                                <td className="color_text_6">13</td>
                                                <td className="color_text_6">45</td>
                                                {/* <td className="color_text_6">22.41%</td> */}
                                            </tr><tr role="row" className="odd">
                                                <td tabIndex="0" className="sorting_1">
                                                    Adivasi Neelgiri Herbal Hair Oil 125ML (Pack Of 2)
                                                </td>
                                                <td className="color_text_6">95</td>
                                                <td className="color_text_6">0</td>
                                                <td className="color_text_6">36</td>
                                                <td className="color_text_6">1</td>
                                                <td className="color_text_6">13</td>
                                                <td className="color_text_6">45</td>
                                                {/* <td className="color_text_6">22.41%</td> */}
                                            </tr><tr role="row" className="even">
                                                <td tabIndex="0" className="sorting_1">
                                                    BLACK Hair Color Shampoo for Men &amp; Women😍😍
                                                </td>
                                                <td className="color_text_6">95</td>
                                                <td className="color_text_6">0</td>
                                                <td className="color_text_6">36</td>
                                                <td className="color_text_6">1</td>
                                                <td className="color_text_6">13</td>
                                                <td className="color_text_6">45</td>
                                                {/* <td className="color_text_6">22.41%</td> */}
                                            </tr><tr role="row" className="odd">
                                                <td tabIndex="0" className="sorting_1">
                                                    ORGANIC GREY REVERSE SHAMPOO BAR😍😍 (PACK OF 2)
                                                </td>
                                                <td className="color_text_6">95</td>
                                                <td className="color_text_6">0</td>
                                                <td className="color_text_6">36</td>
                                                <td className="color_text_6">1</td>
                                                <td className="color_text_6">13</td>
                                                <td className="color_text_6">45</td>
                                                {/* <td className="color_text_6">22.41%</td> */}
                                            </tr><tr role="row" className="even">
                                                <td tabIndex="0" className="sorting_1">
                                                    Skin Whitening &amp; Brightening Cream ( Buy 1 Get 1 Free )
                                                </td>
                                                <td className="color_text_6">38</td>
                                                <td className="color_text_6">0</td>
                                                <td className="color_text_6">36</td>
                                                <td className="color_text_6">1</td>
                                                <td className="color_text_6">13</td>
                                                <td className="color_text_6">45</td>
                                                {/* <td className="color_text_6">22.41%</td> */}
                                            </tr><tr className="odd" role="row">
                                                <td tabIndex="0" className="sorting_1">Total</td>
                                                <td className="">258</td>
                                                <td className="">13</td>
                                                <td className="">99</td>
                                                <td className="">6</td>
                                                <td className="">28</td>
                                                <td className="">112</td>
                                                {/* <td className="">20.00%</td> */}
                                            </tr></tbody>
                                    </table></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            

        </>
    )
}

export default OrderStatusSection

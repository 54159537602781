import React, { useEffect, useRef, useState } from 'react'
import NewOrder from './newOrder';
import FailedToSync from './failedToSync';
import ConfirmOrder from './confirmOrder';
import CancelledOrder from './cancelledOrder';
import { ordersData } from '../../jsonData/order'; 
import { confirmOrder } from '../../jsonData/order_confirm'; 
import { Link } from 'react-router-dom';
import EditOrderModel from './EditOrderModel';


const OrderSection = () => {
 

    const [activeTab, setActiveTab] = useState('pendingOrders');

    const [newOrders ,setNewOrders] = useState(ordersData.NewOrder);
    const [confirmOrders ,setConfirmOrders] = useState(confirmOrder.NewOrder);
   

   
    const changeTab=(tab, event)=>{
        event.preventDefault();
        setActiveTab(tab); 
    }


  
     

  return (
    <>
      
      <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="white_card card_height_100 mb_20 ">
                                    <div className="white_card_header">
                                        <div className="m-0 box_header">
                                            <div className="main-title">
                                              <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <select className="nice_Select wide" id="storeSelect">
                                                            <option value="1">RouteWay</option>
                                                            <option value="2">Shopify</option>
                                                        </select>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="header_more_tool">
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle" id="dropdownMenuButton"
                                                        data-toggle="dropdown">
                                                        <i className="ti-more-alt"></i>
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-right"
                                                        aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#"> <i className="ti-trash"></i>
                                                            Delete</a>
                                                        <a className="dropdown-item" href="#"> <i className="ti-printer"></i>
                                                            Print</a>
                                                        <a className="dropdown-item" href="#"> <i
                                                                className="fa fa-download"></i> Download</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div className="white_card_body QA_section orderList">
                                        <div className="bulder_tab_wrapper">
                                            <ul className="nav" id="myTab" role="tablist">

                                            <li className="nav-item">
                                                    <Link className={`nav-link ${activeTab === 'pendingOrders' ? 'active' : ''}`} id="pending" data-toggle="tab" to="#pendingOrder" 
                                                    onClick={(e)=>changeTab('pendingOrders',e)}><i className="fa-solid fa-file-invoice"></i> Pending Orders <span className="orderQty">{newOrders.length}</span></Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link className={`nav-link ${activeTab === 'ordConfirm' ? 'active' : ''}`} id="odrConfirm" data-toggle="tab" to="#ordConfirm" onClick={(e)=>changeTab('ordConfirm',e)}><i className="fa-solid fa-circle-exclamation"></i> Confirm <span className="orderQty">{confirmOrders.length}</span></Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link className={`nav-link ${activeTab === 'shippedOrder' ? 'active' : ''}`}   data-toggle="tab"
                                                     to="#shippedOrder" onClick={(e)=>changeTab('shippedOrder',e)}><i className="fa-solid fa-circle-exclamation"></i> 
                                                     Shipped Orders <span className="orderQty">0</span></Link>
                                                </li>

                                                {/* <li className="nav-item">
                                                    <a className={`nav-link ${activeTab === 'NewOrder' ? 'active' : ''}`} id="newOrderTab" data-toggle="tab" href="#NewOrder" 
                                                    onClick={(e)=>changeTab('NewOrder',e)}><i className="fa-solid fa-cart-plus"></i> New order <span className="orderQty">{newOrders.length}</span></a>
                                                </li> */}
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${activeTab === 'faildToSync' ? 'active' : ''}`} id="faildToSyncTab" data-toggle="tab" to="#faildToSync" onClick={(e)=>changeTab('faildToSync',e)}><i className="fa-solid fa-circle-exclamation"></i> Failed to Sync <span className="orderQty">2</span></Link>
                                                </li>
                                               
                                                <li className="nav-item">
                                                    <a className={`nav-link ${activeTab === 'odrCancel' ? 'active' : ''}`} id="odrCancel" data-toggle="tab" href="#odrCancel"   onClick={(e)=>changeTab('odrCancel',e)}><i className="fa-solid fa-circle-exclamation"></i> Cancel <span className="orderQty">2</span></a>
                                                </li> 

                                                <li className="nav-item">
                                                    <a className={`nav-link ${activeTab === 'allOrder' ? 'active' : ''}`} id="odrCancel" data-toggle="tab" href="#odrCancel"  
                                                     onClick={(e)=>changeTab('allOrder',e)}><i className="fa-solid fa-circle-exclamation"></i> All Order <span className="orderQty">100</span></a>
                                                </li> 
                                            </ul>
                                        </div>
                                        <div>
                                            <div>
                                                <div className="mt-3 QA_table tab-content" id="myTabContent">
                                                    <div id="" className="dataTables_wrapper no-footer ">
                                                        
                                                           <NewOrder activeTab ={activeTab} orders={newOrders}  />

                                                           <FailedToSync activeTab={activeTab} />

                                                           <ConfirmOrder activeTab={activeTab} orders = {confirmOrders}/>

                                                            <CancelledOrder activeTab={activeTab} />
                                                        {/* </table> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div >
                                               
                                            </div>
                                            <div >
                                             
                                            </div>
                                            <div >
                                             
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    

    </>
  )
}

export default OrderSection
